import React from "react";
import { Field } from "formik";

import { Textarea as ChakraTextarea, Text } from "@chakra-ui/core";

export const Textarea = (props) => (
    <>
        {props?.label?.length > 0 && (
            <Text mb="8px" fontWeight={600}>
                {props.label}
                {props.required && (
                    <Text as="sup" color="brand.red">
                        *
                    </Text>
                )}
            </Text>
        )}
        <Field name={props.name}>
            {({ field, form }) => (
                <ChakraTextarea
                    borderColor={
                        field?.value?.length > 0
                            ? "brand.slateGrey"
                            : "brand.lightGrey"
                    }
                    color={"brand.black"}
                    _hover={{ borderColor: `brand.black` }}
                    _placeholder={{ color: "brand.slateGrey" }}
                    fontSize={`14px`}
                    borderRadius={0}
                    backgroundColor="brand.lightGrey"
                    _focus={{
                        borderColor: `brand.orange`,
                    }}
                    mb={
                        props.help
                            ? `5px`
                            : {
                                  base: `15px`,
                                  md: `30px`,
                              }
                    }
                    onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                    }
                    isDisabled={form.isSubmitting || props.disabled}
                    {...props}
                />
            )}
        </Field>
        {props?.help?.length > 0 && (
            <Text
                color="brand.grey"
                fontSize="sm"
                mb={{
                    base: `15px`,
                    md: `30px`,
                }}
            >
                {props.help}
            </Text>
        )}
    </>
);
