import React from "react";
import { Button as ChakraButton } from "@chakra-ui/core";
import { darken, lighten } from "polished";
import { primary, secondary } from "./variants";

const variants = {
  primary,
  secondary,
};

export const Button = (props) => {
  const {
    children,
    variant = "primary",
    variantStyle = "solid",
    isDark,
    isDisabled,
    bold,
    underline,
    italic,
    uppercase,
    strikethrough,
    ...restProps
  } = props;
  const hoverColor = {
    ...(!isDisabled && {
      _hover: {
        color: `white`,
        bg: isDark
          ? lighten(0.15, variants[variant].accentColor)
          : darken(0.05, variants[variant].accentColor),
      },
      _active: {
        bg: isDark
          ? lighten(0.18, variants[variant].accentColor)
          : darken(0.08, variants[variant].accentColor),
      },
    }),
  };
  const textStyles = {
    ...(bold && { fontWeight: "bold" }),
    ...(underline && { textDecoration: "underline" }),
    ...(strikethrough && { textDecoration: "line-through" }),
    ...(italic && { fontStyle: "italic" }),
  };

  return (
    <ChakraButton
      variant={variantStyle}
      color={variants[variant].textColor}
      fontFamily={`body`}
      isDisabled={isDisabled}
      fontSize="1rem"
      minH="45px"
      px="30px"
      {...textStyles}
      {...{
        ...variants[variant][`sharedProps`],
        ...(variantStyle === `solid` && {
          bg: variants[variant].accentColor,
          ...hoverColor,
          ...variants[variant].solidProps,
          border: "none",
        }),
        ...(variantStyle === `outline` && {
          ...hoverColor,
          borderColor: variants[variant].accentColor,
          borderWidth: "2px",
          backgroundColor: "transparent",
          ...variants[variant].outlineProps,
        }),
        ...(variantStyle === `ghost` && {
          ...hoverColor,
          ...variants[variant].ghostProps,
        }),
        ...(variantStyle === `link` && {
          padding: "",
          ...{
            ...(isDisabled && {
              _hover: { textDecoration: `none` },
            }),
          },
          _active: {
            color: darken(0.05, variants[variant].textColor),
          },
          ...variants[variant].linkProps,
        }),
        ...restProps,
      }}
    >
      {children}
    </ChakraButton>
  );
};

Button.defaultProps = {
  isDark: false,
  isDisabled: false,
  variant: "primary",
  variantStyle: "solid",
};
