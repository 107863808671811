export const primary = {
  accentColor: "rgba(235,93,11,1)",
  textColor: "rgba(255,255,255,1)",
  sharedProps: {
    px: "1em",
    lineHeight: "27px",
    height: "100%",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: 600,
    minWidth: "170px",
    minHeight: "45px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  solidProps: { color: "brand.white" },
  outlineProps: { color: "brand.orange" },
};

export const secondary = {
  textColor: "rgba(255,255,255,1)",
  accentColor: "rgba(0,0,0,1)",
  sharedProps: {
    px: "1em",
    lineHeight: "27px",
    height: "100%",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: 600,
    minWidth: "170px",
    minHeight: "45px",
    color: "brand.white",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  solidProps: {
    backgroundColor: "brand.black",
    _hover: {
      color: "brand.white",
      backgroundColor: "brand.darkTeal",
      textDecoration: "none",
    },
    _active: {
      color: "brand.white",
      backgroundColor: "brand.darkTeal",
      textDecoration: "none",
    },
  },
};
