import React from "react";
import ReactSelect, { components } from "react-select";

import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { Stack, Text } from "@chakra-ui/core";
import { EditorTheme } from "../../theme";

export const Select = (props) => {
  const { name, options, label, required } = props;

  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <>
            {label?.length > 0 && (
              <Text mb="8px" fontWeight={600}>
                {label}
                {required && (
                  <Text as="sup" color="brand.red">
                    *
                  </Text>
                )}
              </Text>
            )}

            <Stack
              mb={
                props.help
                  ? "2px"
                  : {
                      base: `15px`,
                      md: `30px`,
                    }
              }
              justifyContent="center"
            >
              <ReactSelect
                onChange={(item) => {
                  form.setFieldValue(field.name, item.value);
                }}
                {...(field?.value?.length > 0 && {
                  value: options.filter(
                    (option) => option.label === field.value
                  ),
                })}
                options={options}
                className="react-select"
                styles={{
                  control: (provided, { isFocused }) => ({
                    ...provided,
                    padding: "3px 5px",
                    fontWeight: 600,
                    backgroundColor: EditorTheme.colors.brand.white,
                    borderColor: EditorTheme.colors.brand.black,
                    borderWidth: "2px",
                    color: EditorTheme.colors.brand.black,
                    boxShadow: isFocused ? "none" : "none",
                    fontSize: "14px",
                    fontFamily: EditorTheme.fonts.body,
                    borderRadius: "0px",
                    "&:hover": {
                      borderColor: EditorTheme.colors.brand.black,
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: EditorTheme.colors.brand.black,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "100%",
                    margin: "0",
                    left: "0",
                    padding: "0px",
                    color: EditorTheme.colors.brand.black,
                    backgroundColor: EditorTheme.colors.brand.white,
                    border: "1px solid",
                    borderColor: EditorTheme.colors.brand.lightGrey,
                    borderRadius: "0px 0px 3px 3px",
                  }),
                  option: (provided, { isDisabled, isFocused }) => ({
                    ...provided,
                    padding: "10px 15px",
                    fontSize: "14px",
                    color: EditorTheme.colors.brand.black,
                    opacity: isDisabled ? 0.7 : 1,
                    fontWeight: 600,
                    backgroundColor: isFocused
                      ? EditorTheme.colors.brand.orange
                      : EditorTheme.colors.brand.white,
                    "&:active": {
                      backgroundColor: EditorTheme.colors.brand.white,
                    },
                  }),
                }}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                data-testid="select-input"
              />
            </Stack>
          </>
        )}
      </Field>

      {props?.help?.length > 0 && props?.type !== "hidden" && (
        <Text
          color="brand.grey"
          fontSize="sm"
          mb={{
            base: `15px`,
            md: `30px`,
          }}
        >
          {props.help}
        </Text>
      )}
    </>
  );
};

const CaretDownIcon = () => {
  return (
    <Text color={`black`}>
      <FontAwesomeIcon
        icon={faChevronDown}
        style={{
          height: `14px`,
          width: `14px`,
          display: `flex`,
          color: EditorTheme.colors.brand.black,
        }}
      />
    </Text>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
