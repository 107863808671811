import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
  sm: ["100%", "576px"],
  md: ["100%", "768px"],
  lg: ["100%", "992px"],
  xl: ["1210px", "1210px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
  bp.push(size[1]);
  return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
  ...theme,
  breakpoints,
  colors: {
    ...theme.colors,
    brandBlack: {
      500: "#000000", // used for input elements
    },
    brandWhite: {
      500: "#FFFFFF", // used for input elements
    },
    brandOrange: {
      500: "#EB5D0B", // used for input elements
    },
    brand: {
      black: "#000000",
      orange: "#EB5D0B",
      lightOrange: "#F5AE85",
      darkTeal: "#374F50",
      slateGrey: "#ABB9C2",
      darkPink: "#7B264B",
      white: "#FFFFFF",
      lightGrey: "#EEEEEE",
      grey: "#555555",
      darkGrey: "#222222",
      red: "#BD2F2E",
    },
  },
  fonts: {
    ...theme.fonts,
    heading: "proxima-nova, sans-serif",
    body: "proxima-nova, sans-serif",
  },
};

export const AdminTheme = {
  breakpoints: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 992,
    desktop: 1024,
    desktopL: 1700,
  },
};

// Global styles designs: link to the client's style guide if there is one.
const base = css`
  body {
    background-color: ${EditorTheme.colors.brand.white};
    color: ${EditorTheme.colors.brand.black};
    font-family: ${EditorTheme.fonts.body};
  }
`;

const listStyles = css`
  ol {
    font-size: 18px;
    list-style-type: decimal;
  }

  ul:not([class^="DayPicker"]):not([class="indicators"]) {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;
    padding-left: 0;
    font-size: 18px;

    li {
      margin-bottom: 15px;
      position: relative;

      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        height: 10px;
        width: 10px;
        margin-right: 10px;
        background-color: ${EditorTheme.colors.brand.orange};
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .react-slideshow-container {
    + ul.indicators {
      li {
        width: 25px !important;
        height: 5px !important;
        margin-right: 15px !important;

        @media all and (min-width: ${EditorTheme.breakpoints.md}) {
          width: 40px !important;
          margin-right: 30px !important;
        }

        &:last-of-type {
          margin-right: 0 !important;
        }

        .each-slideshow-indicator {
          opacity: 0.8 !important;

          &:hover {
            opacity: 1 !important;
          }

          &:before {
            width: 100% !important;
            height: 5px !important;
            border-radius: 0 !important;
            background: ${EditorTheme.colors.brand.grey} !important;
          }

          &.active {
            opacity: 1 !important;

            &:before {
              background: ${EditorTheme.colors.brand.orange} !important;
            }
          }
        }
      }
    }
  }

  ul:not([class^="DayPicker"]) {
    li {
      padding-left: 15px;

      &:before {
        position: absolute;
        left: 0;
        top: 10px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: ${EditorTheme.colors.brand.white};
      }
    }
  }
`;

const headings = css`
  h1 {
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 15px;
    font-weight: 300;

    @media (max-width: 478px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 300;

    + h2 {
      margin-top: -15px;
    }

    @media (max-width: 478px) {
      font-size: 22px;
      line-height: 22px;
    }

    &.heading2-huge {
      font-size: 50px;
      line-height: 50px;
    }

    &.heading2-line {
      position: relative;
      margin-bottom: 30px;
      margin-top: 30px;
      padding-bottom: 15px;

      &:before {
        content: "";
        height: 2px;
        width: 100%;
        background-color: ${EditorTheme.colors.brand.black};
        position: absolute;
        bottom: -5px;
      }

      &:after {
        content: "";
        height: 19px;
        width: 2px;
        background-color: ${EditorTheme.colors.brand.black};
        position: absolute;
        bottom: -3px;
        right: 0;
      }
    }
  }

  h3 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 15px;

    @media (max-width: 478px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  h6 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  strong {
    font-weight: 700;
  }

  blockquote {
    border-left: 0 !important;
    font-size: 30px;
    font-weight: 300;
    font-style: inherit !important;
    line-height: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    overflow: visible !important;
    padding-bottom: 15px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;

    @media (max-width: 478px) {
      font-size: 22px;
      line-height: 22px;
    }

    &:before {
      background-color: ${EditorTheme.colors.brand.black};
      bottom: -5px;
      content: "";
      height: 4px;
      position: absolute;
      width: 100%;
    }
  }
`;

const fontSizeOptions = css`
  .text-tiny {
    font-size: 0.7em;
  }

  .text-small {
    font-size: 0.85em;
  }

  .text-big {
    font-size: 24px;
    line-height: 30px;
  }

  .text-huge {
    font-size: 1.8em;
  }
`;

const table = css`
  .table {
    width: 100%;
    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid ${EditorTheme.colors.brand.grey};
    }
  }
`;

const focusVisible = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

const reactPage = css`
  .ory-row {
    a:not([type="button"]) {
      text-decoration: none;
      font-weight: 600;
      color: ${EditorTheme.colors.brand.black};

      &:hover {
        text-decoration: underline;
        color: ${EditorTheme.colors.brand.orange};
      }
    }

    .content__button {
      border-radius: 0;
      min-width: 170px;
      font-size: 14px;
      font-weight: 600;
      white-space: normal;
      line-height: 27px;
    }

    .container__match-height {
      .content__image {
        margin: 0 !important;
        height: auto !important;

        > * {
          aspect-ratio: 2/1;
          height: auto !important;
        }
      }
    }

    .content__image {
      margin-bottom: 0;
    }

    .container__match-height {
      .content__text {
        display: table !important;

        * {
          display: block !important;
        }
      }
    }

    .image {
      display: table !important;
      clear: both !important;
      text-align: center !important;
      margin: 1em auto !important;

      &.image-style-side {
        float: right;
        margin-left: 1.5em !important;
        max-width: 50% !important;

        img {
          display: block !important;
          margin: 0 auto !important;
          max-width: 100% !important;
          height: auto !important;
          min-width: 50px !important;
        }
      }
    }

    .content__text {
      p {
        font-size: 18px;
        line-height: 24px;
      }
      blockquote {
        p {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }

    .react-select {
      p {
        margin-bottom: 0;
      }
    }

    figure.table {
      table {
        margin: 15px 0;
        min-width: 100%;
        font-size: 14px;

        tbody {
          > tr {
            &:first-of-type {
              font-weight: bold;
              font-size: 16px;
              background-color: ${EditorTheme.colors.brand.black} !important;
              color: ${EditorTheme.colors.brand.white};
            }
            &:nth-child(odd) {
              background-color: rgba(255, 255, 255, 0.4);
            }

            &:nth-child(even) {
              background-color: transparent;
            }
          }

          tr {
            td {
              padding: 15px;
            }
          }
        }
      }
    }

    ol {
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
      position: relative;
      padding-left: 0;
      font-size: "14px";
      counter-reset: list-number;

      li {
        margin-bottom: 15px;
        position: relative;
        display: flex;
        counter-increment: list-number;
        &:before {
          display: block;
          content: "";
          margin-right: 5px;
          content: counter(list-number) ".";
        }

        strong {
          display: contents;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      ~ div {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const carousel = css`
  .carousel-root {
    width: 100%;
  }

  .carousel {
    ul {
      margin-bottom: 0;
      list-style: none;

      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  .carousel .slide {
    text-align: left;
    background: transparent;

    ul {
      margin-bottom: 0;
      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  .carousel.carousel-slider {
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .carousel {
    .control-dots {
      display: flex;
      justify-content: center;
      bottom: -40px;
    }
  }
`;

export const GlobalStyles = css`
  ${base}
  ${listStyles}
    ${headings}
    ${fontSizeOptions}
    ${table}
    ${carousel}
    ${focusVisible}
    ${reactPage}
`;
